import React from "react"
import { withStyles } from "@material-ui/core/styles"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails)

const ExpansionPanelGrey = props => {
  const [expanded, setExpanded] = React.useState(-1)

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div>
      {props.panel.map((item, index) => {
        return (
          <ExpansionPanel
            key={index}
            square
            expanded={expanded === index}
            onChange={handleChange(index)}
          >
            <ExpansionPanelSummary
              aria-controls="faqpanel-content"
              id="faqpanel-header"
            >
              <Typography>{item.title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{item.component}</ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
    </div>
  )
}

export default ExpansionPanelGrey
