import React from "react"
import SectionTitleDescriptionCta from "../section/section-title-description-cta"
import styled from "styled-components"
import ExpansionPanel from "../expansionpanel/expansion-panel"
import SubmitForm from "./submit-form"
import {
  ContactOptionsContainer,
  ContactOptionsContentContainer,
} from "./components"
import { ContactSales, IconButtonContact } from "./contact"
import LiveHelp from "@material-ui/icons/LiveHelp"

const IconButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Contact = () => {
  const faqpanel = [
    {
      title: "Click here to check our comprehensive online documentation.",
      component: (
        <IconButtonContainer>
          <IconButtonContact
            icon={<LiveHelp style={{ fontSize: 60 }} />}
            text={"Documentation"}
            link={"https://docs.tigase.net"}
            isLinkExternal={true}
          />
        </IconButtonContainer>
      ),
    },
    {
      title: "Click here for free help, questions or bug reports.",
      component: (
        <IconButtonContainer>
          <IconButtonContact
            icon={<LiveHelp style={{ fontSize: 60 }} />}
            text={"Tigase on GitHub"}
            link={"https://github.com/tigase"}
            isLinkExternal={true}
          />
        </IconButtonContainer>
      ),
    },
    {
      title:
        "Click here or send an email to <support AT tigase.net> for paid assistance or consulting services. " +
        "You can also report security issues here.",
      component: <SubmitForm type="support" plan="" />,
    },
    {
      title:
        "Click here if you have standard or higher support level to go directly to our support tracking system.",
      component: (
        <IconButtonContainer>
          <IconButtonContact
            icon={<LiveHelp style={{ fontSize: 60 }} />}
            text={"Tigase Tracking System"}
            link={"https://projects.tigase.net/"}
            isLinkExternal={true}
          />
        </IconButtonContainer>
      ),
    },
    {
      title: "Click here if you are looking for sales contact.",
      component: (
        <IconButtonContainer>
          <ContactSales />
        </IconButtonContainer>
      ),
    },
  ]

  return (
    <>
      <ContactOptionsContainer style={{ minHeight: "90vh" }}>
        <ContactOptionsContentContainer>
          <SectionTitleDescriptionCta
            title="Technical support assistance"
            description="Search for most common Q&A or write us a direct ticket with a request."
          />
          <ExpansionPanel panel={faqpanel} />
        </ContactOptionsContentContainer>
      </ContactOptionsContainer>
    </>
  )
}

export default Contact
